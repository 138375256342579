<template>
  <div class="animated fadeIn">
    <b-card header="New Product">
      <b-form @submit.prevent="saveData" @reset="GoBack">
        <div class="example-avatar">
          <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active"></div>
          <div class="avatar-upload" v-show="!edit">
            <div class="text-center p-2">
              <label for="avatar">
                <img :src="files.length ? files[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'" class="rounded" />
              </label>
            </div>
            <div class="text-center p-2">
              <file-upload
                extensions="gif,jpg,jpeg,png,webp"
                accept="image/png, image/gif, image/jpeg, image/webp"
                name="avatar"
                class="btn btn-primary"
                :custom-action="customUpload"
                :drop="!edit"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload"
              >Upload Image</file-upload>
            </div>
          </div>

          <div class="avatar-edit" v-show="files.length && edit">
            <div class="avatar-edit-image" v-if="files.length">
              <img ref="editImage" :src="files[0].url" />
            </div>
            <div class="text-center p-4">
              <button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear">Cancel</button>
              <button type="submit" class="btn btn-primary" @click.prevent="editSave">Save</button>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class=" table-p" style="width: 700px;">
            <tr>
              <td><nav style="width: 80px;">{{ $t("message.machineType") }}</nav></td>
              <td>
                <b-form-select v-model="product.type" :options="machineType" text-field="name" value-field="name" />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.productType") }}</td>
              <td>
                <b-form-select v-model="product.productType" :options="productType" text-field="name" value-field="value" />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.sku") }}</td>
              <td>
                <b-form-input v-model="product.sku" required />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.productName") }}</td>
              <td>
                <b-form-input v-model="product.name" required />
              </td>
            </tr>
            <template v-if="
                product.productType === 'washer' ||
                product.productType === 'dryer'
              ">
              <tr>
                <td>{{ $t("message.tmpOrTime") }}</td>
                <td>
                  <b-form-input type="number" v-model="product.details.value" />
                </td>
              </tr>
            </template>
            <tr>
              <td>{{ $t("message.description") }}</td>
              <td>
                <b-form-input v-model="product.description" required />
              </td>
            </tr>
          </table>
        </div>
        <div align="right">
          <b-button type="reset" @click="GoBack()" variant="secondary" :active="true" size="lg">{{ $t("message.back") }}</b-button>
          <b-button type="submit" class="ml-2" variant="success" :active="true" size="lg">
            {{
            $t("message.save")
            }}
          </b-button>
        </div>
      </b-form>
    </b-card>
    <!--
      <div>
        <b-card header="Add Categories">
          <b-form-select v-model="selected" :options="options" class="mb-3" />
          <div>Selected: <strong>{{ selected }}</strong></div>
          <b-button variant="primary" :active="true">Category 1</b-button>
          <b-button variant="primary" :active="true">Category 2</b-button> 
        </b-card>
      </div>
    -->
  </div>
</template>

<script>
import { DOMAIN } from '../../app.config'
import { buildFormData } from '../../lib/buildFormData'

import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'

export default {
  name: 'NewProduct',
  components: {
    FileUpload
  },
  data() {
    return {
      formData: new FormData(),
      files: [],
      edit: false,
      cropper: false,
      id: this.$route.params.productid,
      mode: this.$route.params.mode,
      product: {
        type: 'vending',
        details: {},
        cost: 0,
        stdPrice: 0,
        width: 0,
        height: 0
      },
      image: '',
      imageData: '',
      machineType: {},
      productType: [
        {
          name: 'น้ำกระป๋อง',
          value: 'can'
        },
        {
          name: 'ขนม',
          value: 'snack'
        },
        {
          name: 'เครื่องซักผ้า',
          value: 'washer'
        },
        {
          name: 'เครื่องอบผ้า',
          value: 'dryer'
        },
        {
          name: 'น้ำยาปรับผ้านุ่ม',
          value: 'softener'
        },
        {
          name: 'น้ำยาซักผ้า',
          value: 'detergent'
        }
      ]
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  mounted() {
    this.getMachineType()
    if (this.mode === 'create') {
      console.log('create')
    } else if (this.mode === 'edit') {
      this.axios
        .get('/product/' + this.id)
        .then(res => {
          this.product = res.data

          if (this.product.image != null) {
            this.files[0] = { url: DOMAIN + '/' + this.product.image }
          } else {
            this.files[0] = { url: DOMAIN + '/public/images/products/tempproductimg.jpg' }
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      console.log('error mode')
    }
  },
  methods: {
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      })
    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    async customUpload(file, component) {
      this.formData.append('file', file.file)
    },
    GoBack() {
      this.$router.push('/product/list')
    },
    getMachineType() {
      this.$Progress.start()
      this.axios
        .get('/machine/type?')
        .then(res => {
          this.$Progress.finish()
          this.machineType = res.data
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('ERROR @MACHINE TYPE')
          if (err.response.status === 401) {
            window.localStorage.clear()
            window.location.reload()
          }
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          console.log(err)
        })
    },
    saveData() {
      delete this.product.createdAt
      delete this.product.updatedAt

      if (this.product.id) {
        this.product.productId = this.product.id
      }

      buildFormData(this.formData, this.product)

      this.axios
        .post(`/product`, this.formData)
        .then(res => {
          console.log(res.data)
          // this.product = ''
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: this.mode === 'create' ? 'New product has been created' : 'Product has been updated'
            })
            this.$router.push('/product/list')
          } else {
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          }
        })
        .catch(err => {
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss">
.table-p {
  width: 50%;
  border: none;
  margin: 0 auto;
}
.table-p td,
.table-p th {
  padding: 8px;
}
.table td {
  margin: 0;
}

.example-avatar .avatar-upload .rounded {
  width: 200px;
  height: 200px;
}
.example-avatar .text-center .btn {
  margin: 0 0.5rem;
}
.example-avatar .avatar-edit-image {
  max-width: 100%;
}
.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-avatar .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>